<template>
  <div class="refCourseList newCourseSlide" v-slick :key="3">
    <div class="slideItem">
      <a :href="`https://theme.npm.edu.tw/3d/`" target="_blank" class="slideItem_dr" title="在新視窗打開3D文物賞析鏈結(另開新視窗)">
        <div class="courseInfo">
          <h3>
            <b>3D文物賞析</b>
          </h3>
          <p v-plaintext>
            70件文物的3D與2D大圖賞析平台，內含文物介紹、影音、展覽、禮品推薦、典藏資料庫及Open data相關連結等豐富資源，提供喜愛故宮文物藝術的使用者透過縮放、轉動、光影顏色或紋理等細部文物材質外，更進一步的認識故宮館藏文物。
          </p>
        </div>
        <div class="courseImg">
          <img src="/img/DigitResource_1.jpg" alt=""/>
        </div>
      </a>
    </div>
    <div class="slideItem">
      <a :href="`https://theme.npm.edu.tw/selection/`" target="_blank" class="slideItem_dr" title="在新視窗打開典藏精選鏈結(另開新視窗)">
        <div class="courseInfo">
          <h3>
            <b>典藏精選</b>
          </h3>
          <p v-plaintext>
            精選故宮文物藝術圖像，提供【依類別】、【依朝代】、【依展場】、【依多媒體】，根據自己的喜好與研究，探索深度的文物故事。
          </p>
        </div>
        <div class="courseImg">
          <img src="/img/DigitResource_2.jpg" alt=""/>
        </div>
      </a>
    </div>
    <div class="slideItem">
      <a :href="`https://theme.npm.edu.tw/npmonline/`" target="_blank" class="slideItem_dr" title="在新視窗打開線上故宮(另開新視窗)">
        <div class="courseInfo">
          <h3>
            <b>線上故宮</b>
          </h3>
          <p v-plaintext>
            為線上資源整合入口網站，包含Podcasting (故宮Podcast宮說宮有理)、文物欣賞與解說相關影片資源，如「文物賞析」單元中「高畫質-國寶新視界」、「文物故事」、「聽我說文物」；「學習鑑賞」單元中「美感學習-經典之美」、「國寶快問快答」、「筆墨行旅」；「新媒體互動」單元中「數位動畫」、「互動影片」；「兒少專區」單元中「兒趣動畫」、「故宮南院臺灣吧」等影片。
          </p>
        </div>
        <div class="courseImg">
          <img src="/img/DigitResource_3.jpg" alt=""/>
        </div>
      </a>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      digitResourceList: [
        {
        }
      ]
    };
  },
};
</script>