/* eslint-disable no-undef */
import $ from 'jquery';
import qs from 'qs';
import user from '../plugins/user'
import userip from '../plugins/user-ip-info'
import router from '../router'

let _token = user.user ? user.user.Token : null;
let _ip = userip.ipInfo ? userip.ipInfo.ip : null;

var blockUI = true;
export default {
    expireHandler: [],

    addOnExpireHandler(callback) {
        for (var k in this.expireHandler) {
            if (this.expireHandler[k] == callback) {
                return;
            }
        }
        this.expireHandler.push(callback);
    },

    removeOnExpireHandler(callback) {
        this.expireHandler.remove(callback);
    },

    raiseOnExpire() {
        this.expireHandler.forEach(handler => {
            handler(this);
        });
    },

    setBlockUI(val) {
        blockUI = val;
    },

    setToken(token) {
        _token = token;
    },
    setIp() {
        userip.getIpInfo()
        .then(ip => {
            _ip = ip.ip;
        });
    },
    getUrl(url) {
        if (_token) {
            if (url.indexOf('?') > 0) {
                url += '&t=' + encodeURIComponent(_token);
            } else {
                url += '?t=' + encodeURIComponent(_token);
            }
            url += '&_=' + new Date().getTime();
            if (_ip) {
                url += '&ip=' + _ip; 
            } else {
                this.setIp();
                url += '&ip=' + _ip; 
            }
        } else {
            if (_ip) {
                url += '?ip=' + _ip; 
            } else {
                this.setIp();
                url += '?ip=' + _ip; 
            }
        }
        return config.host + url;
    },
    handle(p) {
        return new Promise((resolve, reject) => {
            if (window.blockUI && blockUI) {
                window.blockUI();
            }
            p.then(res => {
                if (res.Success) {
                    if (res.Token) {
                        var token = res.Token;
                        user.setToken(token);
                        this.setToken(token);
                    }
                    resolve(res.Result);
                } else {
                    if (res.Status == 3) {
                        console.log("err");
                        this.notFound();
                        return;
                    }
                    reject(res)
                }
            })
                .catch((err) => {
                    console.log(err);
                    if (err.status == 410) {
                        this.raiseOnExpire();
                        return;
                    }
                    reject(err)
                }).always(function () {
                    window.unblockUI();
                });
        });
    },
    fetchData(url) {
        return new Promise((resolve, reject) => {
            url.then(res => {
                resolve(res);
            })
            .catch((err) => {
                reject(err)
            })
        });
    },
    get(url, data) {
        if (!data) {
            return this.handle($.get(this.getUrl(url)));
        } else {
            return this.handle($.get(this.getUrl(url) + qs.stringify(url)));
        }
    },
    getThridParty(url) {
        return this.handle($.get(url));
    },
    post(url, data) {
        return this.handle($.ajax({
            type: "POST",
            url: this.getUrl(url),
            data: JSON.stringify(data),
            contentType: "application/json"
        }));
    },
    multipartPost(url, data) {
        url = this.getUrl(url);
        var fdata = new FormData();
        for (var key in data) {
            fdata.append(key, data[key]);
        }
        return $.ajax({
            type: "POST",
            url: url,
            contentType: false,
            processData: false,
            data: fdata
        });
    },
    put(url, data) {
        return this.handle($.ajax({
            type: "PUT",
            url: this.getUrl(url),
            data: JSON.stringify(data),
            contentType: "application/json"
        }));
    },
    del(url) {
        return this.handle($.ajax({
            type: "DELETE",
            url: this.getUrl(url),
        }));
    },
    hasToken() {
        return token != null && token != undefined && token != "";
    },

    login(data) {
        return this.post('/api/Member/Login', data);
    },
    getHome() {
        return this.get('/api/Home/GetHome');
    },
    getLessonCategories() {
        return this.get('/api/Lesson/GetLessonCategories');
    },
    register(data) {
        return this.post('/api/Member/Register', data);
    },
    getClassRoomCode() {
        return this.get('/api/ClassRoom/GetClassRoomCode');
    },
    getAllLessons() {
        return this.get('/api/Lesson/GetLessons');
    },
    getLessons(ids) {
        return this.get('/api/Lesson/GetLesson/' + ids.join(','));
    },
    createClassRoom(data) {
        return this.post('/api/ClassRoom/CreateClassRoom', data);
    },
    updateClassRoom(data) {
        return this.post('/api/ClassRoom/UpdateClassRoom', data);
    },
    getMyClassRoom() {
        return this.get('/api/ClassRoom/GetMyClassRoom');
    },
    getClassRoom(id) {
        return this.get('/api/ClassRoom/GetClassRoom/' + id);
    },
    delClassRoom(id) {
        return this.del('/api/ClassRoom/DeleteClassRoom/' + id);
    },
    getFullLesson(id) {
        return this.get('/api/Lesson/GetFullLesson/' + id);
    },
    getLessonChapters(id) {
        return this.get('/api/Lesson/GetChapters/' + id);
    },
    getLessonByCategory(id) {
        return this.get('/api/Lesson/GetLessonByCategory/' + id);
    },
    getStudentLessons() {
        return this.get('/api/Lesson/GetStudentLessons');
    },
    searchLesson(id) {
        return this.get('/api/Lesson/Search/' + encodeURIComponent(id));
    },
    search(id) {
        return this.get('/api/Search/KeywordSearch/' + encodeURIComponent(id));
    },
    getRecommand() {
        return this.get('/api/Lesson/GetRecommand');
    },
    getClassRoomByCode(code) {
        return this.get('/api/ClassRoom/GetClassRoomByCode/' + encodeURIComponent(code));
    },
    addMyClassRoom(id) {
        return this.post('/api/ClassRoom/AddMyClassRoom/' + id);
    },
    getClassRoomByLessonID(id) {
        return this.get('/api/ClassRoom/GetClassRoomByLessonID/' + id);
    },
    visit(id) {
        return this.post('/api/Lesson/Visit/' + id);
    },
    learn(id) {
        return this.post('/api/Lesson/Learn/' + id);
    },
    getHomeWork(id) {
        return this.get('/api/HomeWork/GetHomeWork/' + id);
    },
    submitHomeWork(id, data) {
        return this.post('/api/HomeWork/DoHomeWork/' + id, data);
    },
    getHistory() {
        return this.get('/api/Lesson/GetHistory');
    },
    getMe() {
        return this.get('/api/Member/GetMe');
    },
    updateMe(data) {
        return this.post('/api/Member/Update', data);
    },
    getClassRoomTrace() {
        return this.get('/api/ClassRoom/GetClassRoomTrace');
    },
    getLessonSummery(id, cid) {
        return this.get(`/api/Lesson/GetLessonTraceDetail/${id}?cid=${cid}`);
    },
    getTeachPlanCategories() {
        return this.get('/api/TeachPlan/GetTeachPlanCategories');
    },
    getTeachPlans() {
        return this.get('/api/TeachPlan/GetTeachPlans');
    },
    getTeachPlan(id) {
        return this.get('/api/TeachPlan/GetTeachPlan/' + id);
    },
    getTeachPlanFiles(id) {
        return this.get('/api/TeachPlan/GetTeachPlanFiles/' + id);
    },
    updateVisitCount(id) {
        return this.put('/api/TeachPlan/UpdateVisitCount/' + id);
    },
    updateDownloadCount(id) {
        return this.put('/api/TeachPlan/UpdateDownloadCount/' + id);
    },
    getOAuthUrl() {
        return this.get('/api/Member/GetOAuthUrl');
    },
    oAuthLogin(data) {
        return this.post('/api/Member/OAuthLogin', data);
    },
    getLearnedChapter(id) {
        return this.get('/api/lesson/GetLearnedChapter/' + id);
    },
    getChapterProgress(id) {
        return this.get('/api/lesson/GetChapterProgress/' + id);
    },
    updateChapterProgress(data) {
        return this.post('/api/lesson/UpdateChapterProgress', data);
    },
    updateLessonLog(data) {
        return this.post('/api/lesson/UpdateLessonLog', data);
    },
    getNew(){
        return this.get('/api/lesson/GetNew');
    },
    updatePassword(data) {
        return this.post('/api/member/UpdatePassword', data);
    },
    getCaptcha() {
        return this.get('/api/member/GetCaptcha');
    },
    getAudioCaptcha(data) {
        return this.post('/api/member/GetAudioCaptchaAsync', data);
    },
    sendForgetPassword(data) {
        return this.post('/api/member/SendForgetPassword', data);
    },
    resetPassword(data) {
        return this.post('/api/member/ResetPassword', data);
    },
    getMemberIpInfo() {
        return this.fetchData($.get('https://ipinfo.io/json'));
    },
    notFound() {
        return router.push('/404');
    },
}