<template>
  <div class="refCourseList newCourseSlide" v-slick :key="Lessons.length">
    <div class="slideItem" v-for="l in Lessons" :key="l.LessonID" tabIndex = "-1">
      <router-link :to="`/lesson/${l.LessonID}`" target="_blank" class="slideItem_lesson" tabIndex = "-1" title="點擊可跳轉至指定課程(另開新視窗)">
        <div class="courseInfo">
          <h3>
            <b>{{ l.LessonName }}</b>
          </h3>
          <p v-plaintext>
            {{ l.Brief }}
          </p>
        </div>
        <div class="courseImg">
          <img :src="l.ImageUrl|imageCDN" alt="課程主圖片"/>
          <div class="cateTag" v-if="l.LessonCategoryID == 1">師培課程<img src="/img/play2.svg" alt="師培課程連結"/></div>
          <div class="cateTag" v-else>學生課程<img src="/img/play2.svg" alt="學生課程連結"/></div>
          <div class="time">{{ l.VideoLength | chhmmss }}</div>
        </div></router-link
      >
    </div>
  </div>
</template>
<script>
export default {
  props: ["loader"],
  data() {
    return {
      Lessons: [],
    };
  },
  async mounted() {
    this.Lessons = await this.loader();
  },
};
</script>